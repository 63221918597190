import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';

import { PresentationPreview } from '@/presentations/types';
import { getPresentationPreviewUrl } from '@/presentations/utils';
import cn from 'classnames';
import { getFullUrl } from 'maven-ui-kit/core';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import Card from 'components/card';
import { CardSize } from 'components/card/type';

import ClampedEllipsis from 'ui-kit/clamped-ellipsis';

import { getPresentationLink } from 'utils/link';

import styles from './styles.module.scss';

interface PreviewProps {
  presentation: PresentationPreview;
}

const Preview: React.FC<PreviewProps> = ({ presentation }) => {
  const { query } = useCustomRouter();

  const { id, name } = presentation;

  const resources = [
    getPresentationPreviewUrl(presentation),
    getPresentationPreviewUrl(presentation, 'jpg'),
    getFullUrl('cdn/notfound.png'),
  ];

  const [resourceIndex, setResourceIndex] = React.useState(0);

  const href = getPresentationLink(id);

  return (
    <Link
      href={{
        ...href,
        query: {
          ...(Object(href.query) ?? {}),
          ...query,
        },
      }}
      passHref>
      <div className={cn(styles.item, 'pointer')}>
        <Card classes={styles.itemImage} size={CardSize.ExtraSmall}>
          <Image
            onError={() => {
              setResourceIndex((prevState) => prevState + 1);
            }}
            width={163}
            height={122}
            src={resources[resourceIndex]}
            unoptimized
          />
        </Card>

        <ClampedEllipsis maxWidth={200} lineClamp={2}>
          {name}
        </ClampedEllipsis>
      </div>
    </Link>
  );
};

export default Preview;
