import * as React from 'react';

import { PresentationPreview } from '@/presentations/components';

import { useGetPresentationsQuery } from 'store/api';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import NoDataContainer from 'components/no-data-container';
import Preloader from 'components/preloader';

import styles from './presentations.module.scss';

const Presentations: React.FC = () => {
  const { query } = useCustomRouter();

  const { sort, order, text } = query;

  const {
    data: presentations = [],
    isLoading,
    isFetching,
  } = useGetPresentationsQuery({
    sort,
    order,
    text,
  });

  const isNoData = presentations.length === 0;

  if (isLoading || isFetching) return <Preloader />;

  if (isNoData) return <NoDataContainer />;

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {presentations.map((presentation) => {
          const { id } = presentation;

          return (
            <li key={id}>
              <PresentationPreview presentation={presentation} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Presentations;
