export interface CardProps {
  classes?: string;
  size?: CardSize;
}

export enum CardSize {
  ExtraSmall = 'XS',
  Small = 'S',
  Medium = 'M',
}
