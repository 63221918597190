import { FC } from 'react';

import cn from 'classnames';

import { CardProps, CardSize } from './type';

import styles from './card.module.scss';

const Card: FC<CardProps> = (props) => {
  const { children, classes, size = CardSize.Medium } = props;

  const className = cn('flex-row', styles.card, classes, {
    [styles[`cardSize_${size}`]]: true,
  });

  return <section className={className}>{children}</section>;
};

export default Card;
