import { getFullUrl } from 'maven-ui-kit/core';

import { PresentationPreview } from './types';

export const getPresentationPreviewUrl = (
  { webhostUrl, directory }: PresentationPreview,
  format: 'png' | 'jpg' = 'png',
) => {
  const url = `${getFullUrl(webhostUrl)}/${directory}/thumbnail.${format}`;

  return url;
};
