import { FC } from 'react';

import cn from 'classnames';

import { lexics } from 'data/lexics';

import styles from './no-data-container.module.scss';

const NoDataContainer: FC = () => {
  return (
    <div className={cn('full-width', styles.container)}>
      <span className={styles.text}>{lexics.no_data}</span>
    </div>
  );
};

export default NoDataContainer;
