import type { NextPage } from 'next';

import HeadPage from 'components/head-page';
import Presentations from 'components/presentations';

const PresentationsPage: NextPage = () => {
  return (
    <>
      <HeadPage />

      <Presentations />
    </>
  );
};

export default PresentationsPage;
