import Head from 'next/head';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { OrganizationState } from 'reducers/organizationSlice';
import { RootState } from 'store';

import { HeadPageProps } from './type';

const HeadPage: React.FC<HeadPageProps> = ({ children, nameSection }) => {
  const { name } = useSelector<RootState, OrganizationState>(
    ({ organization }) => organization,
  );

  const mainText = 'maven.detailing';

  const titleCompany = React.useMemo(() => {
    if (!name) return mainText;

    return `${mainText} - ${name}`;
  }, [name]);

  const titleFull = React.useMemo(() => {
    if (!nameSection) return titleCompany;

    return `${nameSection} - ${titleCompany}`;
  }, [titleCompany]);

  return (
    <Head>
      <title>{titleFull}</title>

      {children}
    </Head>
  );
};

export default HeadPage;
